import React, { useState } from 'react';

const ChatInput = ({ input, setInput, handleSend, isLoading }) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className="fixed bottom-4 left-4 right-4">
      <div className="max-w-2xl mx-auto">
        <div className={`bg-white rounded-xl shadow-lg p-3 flex items-center transition-all duration-300 ${isFocused ? 'ring-2 ring-[#ffcb8e]' : ''}`}>
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && !isLoading && handleSend()}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            placeholder="Type your question..."
            className="flex-grow p-2 bg-transparent focus:outline-none text-gray-900 placeholder-[#c2b8a3]"
            disabled={isLoading}
          />

          <button
            onClick={handleSend}
            className={`p-2 rounded-full ${isLoading ? 'bg-[#c2b8a3]' : 'send-message'} text-white transition duration-300 focus:outline-none focus:ring-2 focus:ring-[#ffcb8e] shadow-sm ml-2`}
            disabled={isLoading}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
            </svg>
          </button>
        </div>
        <p className="text-xs text-center text-[#c2b8a3] mt-2">
          Powered by Welcomatic AI
        </p>
      </div>
    </div>
  );
};

export default ChatInput;