import React from 'react';
import MessageBubble from './MessageBubble';
import LoadingMessage from './LoadingMessage';

const ChatDisplay = ({ messages, isLoading, messagesEndRef }) => {
  return (
    <div className="flex-grow overflow-auto p-4 pb-24 chat-bg chat-scroll-container">
      <div className="max-w-2xl mx-auto">
        <div className="chat-container">
          <div className="space-y-4">
            {messages.map((message, index) => (
              <MessageBubble
                key={index}
                message={message}
                isLatest={index === messages.length - 1}
              />
            ))}

            {isLoading && <LoadingMessage />}
            <div ref={messagesEndRef} className="h-16" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatDisplay;