import React from 'react';

const LoadingMessage = () => {
  return (
    <div className="flex justify-start">
      <div className="bg-white p-4 rounded-2xl rounded-tl-none shadow-md border border-[#e6dfd1] flex items-center">
        <div className="typing-indicator">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
};

export default LoadingMessage;