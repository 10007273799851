import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import ChatInput from './ChatInput';
import ChatHeader from './ChatHeader';
import ChatDisplay from './ChatDisplay';
import './App.css';
const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function ChatbotUI() {
  const { listing_id } = useParams();
  const [messages, setMessages] = useState([
    {
      text: "Hi, what can I help you with today?",
      sender: "bot"
    }
  ]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [listingName, setListingName] = useState('');
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  useEffect(() => {
    const fetchListingName = async () => {
      try {
        console.log(`${BASE_URL}/listing/${listing_id}`);
        const response = await axios.get(`${BASE_URL}/listing/${listing_id}`);
        setListingName(response.data.name);
      } catch (error) {
        console.error("Error fetching listing name:", error);
      }
    };

    fetchListingName();
  }, [listing_id]);

  const handleSend = async () => {
    if (input.trim() === '') return;

    const userMessage = {
      text: input,
      sender: "user"
    };

    setMessages(prev => [...prev, userMessage]);
    setInput('');
    setIsLoading(true);

    // Ensure scroll to bottom happens after the message is added
    setTimeout(scrollToBottom, 100);

    try {
      const response = await axios.post(`${BASE_URL}/listing/${listing_id}/ask`, { text: input });
      const botMessage = {
        text: response.data.answer,
        sender: "bot"
      };
      setMessages(prev => [...prev, botMessage]);
    } catch (error) {
      console.error("Error sending message:", error);
      const errorMessage = {
        text: "Sorry, I couldn't process your request.",
        sender: "bot"
      };
      setMessages(prev => [...prev, errorMessage]);
    } finally {
      setIsLoading(false);
      // Ensure scroll to bottom happens after loading is complete
      setTimeout(scrollToBottom, 100);
    }
  };

  return (
    <div className="flex flex-col h-screen chat-bg font-sans">
      {/* Header */}
      <ChatHeader listingName={listingName} />

      {/* Chat Area */}
      <ChatDisplay
        messages={messages}
        isLoading={isLoading}
        messagesEndRef={messagesEndRef}
      />

      {/* Floating Input Area */}
      <ChatInput
        input={input}
        setInput={setInput}
        handleSend={handleSend}
        isLoading={isLoading}
      />
    </div>
  );
}