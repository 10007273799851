import React from 'react';

const MessageBubble = ({ message, isLatest }) => {
  const isUser = message.sender === 'user';

  return (
    <div
      className={`flex ${isUser ? 'justify-end' : 'justify-start'}
      animate-fadeIn message-container message-fade ${isLatest ? 'latest-message' : ''}`}
    >
      <div
        className={`max-w-[80%] p-4 rounded-2xl ${
          isUser
            ? 'human-message text-white rounded-tr-none shadow-md'
            : 'bot-message text-gray-800 rounded-tl-none shadow-md border border-[#e6dfd1]'
        }`}
      >
        <p className="whitespace-pre-wrap">{message.text}</p>
      </div>
    </div>
  );
};

// Set default props
MessageBubble.defaultProps = {
  isLatest: false
};

export default MessageBubble;