import React from 'react';

const ChatHeader = ({ listingName }) => {
  return (
    <header className="bg-white shadow-sm p-4 sticky top-0 z-10">
      <div className="max-w-2xl mx-auto flex items-center">
        <div className="flex-shrink-0 mr-3">
          <div className="h-10 w-10 rounded-full bg-gradient-to-br from-[#ff7e5f] to-[#feb47b] flex items-center justify-center text-white font-bold shadow-sm">
            {listingName ? listingName.charAt(0).toUpperCase() : 'W'}
          </div>
        </div>

        <div className="flex-1">
          {listingName ? (
            <div>
              <h1 className="text-lg font-bold text-gray-800">{listingName}</h1>
              <p className="text-sm text-[#c2b8a3]">Welcomatic AI</p>
            </div>
          ) : (
            <div>
              <h1 className="text-lg font-bold text-gray-800">Welcomatic AI</h1>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default ChatHeader;